import axios from '@/common/axios'
import qs from 'qs'

export function addInFirst (data) {
  return axios({
    method: 'post',
    url: '/storage/inFirst/add',
    data: qs.stringify(data)
  })
}

export function deleteInFirst (id) {
  return axios({
    method: 'delete',
    url: '/storage/inFirst/delete/' + id
  })
}

export function updateInFirst (data) {
  return axios({
    method: 'put',
    url: '/storage/inFirst/update',
    data: qs.stringify(data)
  })
}

export function selectInFirstInfo (id) {
  return axios({
    method: 'get',
    url: '/storage/inFirst/info/' + id
  })
}

export function selectInFirstList (query) {
  return axios({
    method: 'get',
    url: '/storage/inFirst/list',
    params: query
  })
}

export function reviewInFirst (data) {
  return axios({
    method: 'put',
    url: '/storage/inFirst/review',
    data: qs.stringify(data)
  })
}
