import axios from '@/common/axios'
import qs from 'qs'

export function addMaterialPurchaseStock (data) {
  return axios({
    method: 'post',
    url: '/storage/materialPurchaseStock/add',
    data: qs.stringify(data)
  })
}

export function deleteMaterialPurchaseStock (id) {
  return axios({
    method: 'delete',
    url: '/storage/materialPurchaseStock/delete/' + id
  })
}

export function updateMaterialPurchaseStock (data) {
  return axios({
    method: 'put',
    url: '/storage/materialPurchaseStock/update',
    data: qs.stringify(data)
  })
}

export function selectMaterialPurchaseStockInfo (id) {
  return axios({
    method: 'get',
    url: '/storage/materialPurchaseStock/info/' + id
  })
}

export function selectMaterialPurchaseStockList (query) {
  return axios({
    method: 'get',
    url: '/storage/materialPurchaseStock/list',
    params: query
  })
}
